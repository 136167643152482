<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        :md="getWidthFirst()"
        class="d-flex justify-center align-center"
      >
        <table
          border=0
          cellspacing=0
          cellpadding=0
          height="200px"
          class="elevation-5"
          style="border-collapse: separate;  border-radius: 10px;  -moz-border-radius: 10px;"
        >
          <tr>
            <td
              nowrap
              valign=bottom
              style='padding:0in 3.5pt 0in 3.5pt;height:.2in; border-botton-style:none !important; vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><span style='color:black'>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        top
                        dark
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                        style="margin-bottom: 1.5px;"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <v-card><v-card-text> <span>V reálnej skutočnosti je toto číslo počet oprávnených voličov.</span>

                      </v-card-text></v-card>
                  </v-menu> Počet
                  zaregistrovaných</span></p>

              <p style='margin-bottom:0in;line-height:normal'><span style='color:black'>potenciálnych
                  hlasujúcich:</span></p>
            </td>
            <td
              nowrap
              colspan=2
              style='  border-left:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;'
            >
              <p
                align=center
                style='margin-bottom:0in;text-align:center;  line-height:normal'
              ><span
                  align=center
                  style='margin-bottom:0in;text-align:center;  line-height:normal; color: black !important'
                  class="text-subtitle-1"
                ><strong>
                    {{ referendum.possible_voters_count }}</strong></span></p>
            </td>
          </tr>
          <tr>
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><span style='color:black'><b>Spolu
                    hlasovali:</b></span></p>
            </td>
            <td
              nowrap
              align="right"
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:black'>{{
                                question.votes_count }}</span></b></p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:black'>{{
                                                        Math.round(question.votes_count
                                                            /
                                                            referendum.possible_voters_count * 1000) / 10 }}%</span></b>
              </p>
            </td>
          </tr>
          <tr @click="logRef('question_1_' + question.id, 0)">
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#4CAF50'>ÁNO</span></b><span style='color:#4CAF50'>
                  hlasovali:</span></p>
            </td>
            <td
              nowrap
              valign=bottom
              align="right"
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal;'><b><span style='color:#4CAF50'>{{
                                question.votes_yes_count }}</span></b></p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:#4CAF50'>{{
                                                        Math.round(question.votes_yes_count /
                                                            question.votes_count
                                                            *
                                                            1000) / 10 }}%</span></b>
              </p>
            </td>
          </tr>
          <tr @click="logRef('question_1_' + question.id, 1)">
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#b71c1c'>NIE</span></b><span style='color:#b71c1c'>
                  hlasovali:</span></p>
            </td>
            <td
              nowrap
              valign=bottom
              align="right"
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#b71c1c'>{{
                                question.votes_no_count }}</span></b></p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:#b71c1c'>{{
                                                        Math.round(question.votes_no_count /
                                                            question.votes_count
                                                            *
                                                            1000) / 10 }}%</span></b>
              </p>
            </td>
          </tr>
          <tr @click="logRef('question_1_' + question.id, 2)">
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><span style='color:#3f51b5'>Zdržali
                  sa:</span></p>
            </td>
            <td
              nowrap
              valign=bottom
              align="right"
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#3f51b5'>{{
                                question.votes_abstain_count }}</span></b></p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:#3f51b5'>{{
                                                        Math.round(question.votes_abstain_count /
                                                            question.votes_count * 1000) / 10 }}%</span></b>
              </p>
            </td>
          </tr>
          <tr @click="logRef('question_1_' + question.id, 3)">
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><span style='color:#9E9E9E'>Zrušili
                  hlas:</span></p>
            </td>
            <td
              nowrap
              valign=bottom
              align="right"
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#9E9E9E'>{{
                                question.votes_cancelled_count }}</span></b></p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:#9E9E9E'>{{
                                                        Math.round(question.votes_cancelled_count
                                                            /
                                                            question.votes_count * 1000) / 10 }}%</span></b>
              </p>
            </td>
          </tr>
          <tr @click="logRef('question_2_' + question.id, 4)">
            <td
              nowrap
              valign=bottom
              style='
                                                    border-top:none;padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><span style='color:#E0E0E0'>Nehlasovali:</span>
              </p>
            </td>
            <td
              nowrap
              valign=bottom
              align="right"
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p style='margin-bottom:0in;line-height:normal'><b><span style='color:#E0E0E0'>{{
                                referendum.possible_voters_count - question.votes_yes_count -
                                question.votes_no_count
                                - question.votes_abstain_count - question.votes_cancelled_count }}</span></b>
              </p>
            </td>
            <td
              nowrap
              valign=bottom
              style='border-top:none;
                                                    border-left:none;
                                                    padding:0in 3.5pt 0in 3.5pt;height:.2in;vertical-align: middle;'
            >
              <p
                align=right
                style='margin-bottom:0in;text-align:right;
                                                    line-height:normal'
              ><b><span style='color:#E0E0E0'>{{
                                                        Math.round((referendum.possible_voters_count
                                                            -
                                                            question.votes_yes_count - question.votes_no_count -
                                                            question.votes_abstain_count -
                                                            question.votes_cancelled_count) / referendum.possible_voters_count * 1000) / 10
                                                    }}%</span></b>
              </p>
            </td>
          </tr>

        </table>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="d-flex justify-center align-center"
      >
        <div :id="'question_1_' + question.id">
          <div class="text-center">
            Graf – výsledky len hlasujúcich
          </div>
          <apexchart
            :ref="'question_1_' + question.id"
            type="pie"
            :options="chartOptions1"
            :series="[question.votes_yes_count, question.votes_no_count, question.votes_abstain_count, question.votes_cancelled_count]"
          >
          </apexchart>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="getWidthThird()"
      >
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center align-center text-center pb-0"
          >
            Graf – výsledky všetkých potenciálnych hlasujúcich
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center align-center pt-0"
          >
            <apexchart
              :ref="'question_2_' + question.id"
              type="pie"
              :options="chartOptions2"
              :series="[question.votes_yes_count, question.votes_no_count, question.votes_abstain_count, question.votes_cancelled_count, referendum.possible_voters_count - question.votes_yes_count - question.votes_no_count - question.votes_abstain_count - question.votes_cancelled_count]"
            >
            </apexchart>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PollResults",

  props: {
    referendum: { type: Object, required: true },
    question: { type: Object, required: true },
    oneRow: { type: Boolean, default: false }
  },

  data: () => ({
    chartOptions1: {
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex]
          return '<div class="pa-2" style="background-color:' + w.config.colors[seriesIndex] + ';">' +
            '<span>' + w.config.labels[seriesIndex] + ": " + selected + " (" + (selected / total * 100).toFixed(2) + "%)" + '</span>' +
            '</div>'
        },
      },
      fill: {
        type: 'gradient',
      },

      chart: {
        width: 200,
        type: 'pie',
      },
      legend: {
        show: false
      },
      colors: ['#4CAF50', '#b71c1c', '#3f51b5', '#9E9E9E'],
      labels: ['ÁNO hlasovali', 'NIE hlasovali', 'Zdržali sa', 'Zrušili hlas'],
      responsive: [{
        breakpoint: 540,
        options: {
          style: {
            fontSize: '8px',
          },
          chart: {
            width: 200,
            type: 'pie',
          },
        },
      }]
    },

    chartOptions2: {
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex]
          return '<div class="pa-2" style="background-color:' + w.config.colors[seriesIndex] + ';">' +
            '<span>' + w.config.labels[seriesIndex] + ": " + selected + " (" + (selected / total * 100).toFixed(2) + "%)" + '</span>' +
            '</div>'
        }
      },


      fill: {
        type: 'gradient',
      },

      chart: {
        width: 200,
        type: 'pie',
      },
      legend: {
        show: false
      },
      colors: ['#4CAF50', '#b71c1c', '#3f51b5', '#9E9E9E', '#E0E0E0'],
      labels: ['ÁNO hlasovali', 'NIE hlasovali', 'Zdržali sa', 'Zrušili hlas', 'Nehlasovali'],
      responsive: [{
        breakpoint: 540,
        options: {
          style: {
            fontSize: '8px',
          },
          chart: {
            width: 200,
            type: 'pie',
          },
        },
      }]
    },
  }),

  computed: {
  },


  events: {},

  watch: {},

  mounted: function () {
  },

  methods: {
    getWidthFirst() {
      if (this.oneRow)
        return "4"
      else
        return "8"

    },
    getWidthThird() {
      if (this.oneRow)
        return "4"
      else
        return "12"
    },
    logRef(refId, dataPointId) {
      if (this.$refs[refId].chart.w.globals.selectedDataPoints[0] != dataPointId) {
        this.$refs[refId].toggleDataPointSelection(dataPointId);
      }
      else {
        this.$refs[refId].toggleDataPointSelection(dataPointId);
        window.dispatchEvent(new Event('resize'));
      }
    },
  },
};
</script>>